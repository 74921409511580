// lean-cloud 配置
import AV from "leancloud-storage";

if (process.env.NODE_ENV === "production" && process.env.VUE_APP_PRE_PRODUCTION !== "T") {
  // 线上生产环境
  AV.init({
    appId: "gEfPp00KoDrtc0UV4t9KuuKY-gzGzoHsz",
    appKey: "DYezwZZC5PrRoxqBsc2XJm0A",
    serverURL: "https://ifapi.geekpark.net",
  });
} else if (process.env.NODE_ENV === "production" && process.env.VUE_APP_PRE_PRODUCTION === "T") {
  // 线上测试环境
  AV.init({
    appId: "FysMcu0acNEiznVwBLWMPNx3-gzGzoHsz",
    appKey: "dxfH237ROvlaR1GTXR1JNWdp",
    serverURL: "https://ifapi-test.geekpark.net",
  });
} else {
  // 本地开发环境
  AV.init({
    appId: "FysMcu0acNEiznVwBLWMPNx3-gzGzoHsz",
    appKey: "dxfH237ROvlaR1GTXR1JNWdp",
    serverURL: "https://ifapi-test.geekpark.net",

    // appId: "XymKcKm1QRceVidaEzrRCfm2-gzGzoHsz",
    // appKey: "JacjoWFNOLGvakSAyOOx1Mwh",
    // serverURL: "https://nftshopapi-test.geekpark.net",
  });
}

export default AV;
