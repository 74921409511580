let redirectUriWx = "";
let nftShopApi = "";

// 我的极客公园服务号
let appID = "wx4dd272ff0422a8a6";
let appsecret = "fde0fdee0a17736629b6fbdc843a8dbd";

if (process.env.NODE_ENV === "production" && process.env.VUE_APP_IF_PRODUCTION === "T" && process.env.VUE_APP_PRE_PRODUCTION !== "T") {
  // if.geekpark.net线上生产环境
  redirectUriWx = "https://if.geekpark.net";
  nftShopApi = "https://ifcloud.geekpark.net/api/v1";
} else if (process.env.NODE_ENV === "production" && process.env.VUE_APP_PRE_PRODUCTION !== "T" && process.env.VUE_APP_IF_PRODUCTION !== "T") {
  // if2023.geekpark.net线上生产环境
  redirectUriWx = "https://if2023.geekpark.net";
  nftShopApi = "https://ifcloud.geekpark.net/api/v1";
} else if (process.env.NODE_ENV === "production" && process.env.VUE_APP_PRE_PRODUCTION === "T" && process.env.VUE_APP_IF_PRODUCTION !== "T") {
  // if2023-test.geekpark.net线上测试环境
  redirectUriWx = "https://if2023-test.geekpark.net";
  nftShopApi = "https://ifcloud-test.geekpark.net/api/v1";
} else {
  // 本地开发环境
  redirectUriWx = "https://hutao.vip.cpolar.cn";
  nftShopApi = "https://ifcloud-test.geekpark.net/api/v1";
  // 测试公众号
  // appID = "wx1b1b3df620aa001b";
  // appsecret = "50c2f073efc1295886acb0da9dcf94da";
  // 我的极客公园服务号
  appID = "wx4dd272ff0422a8a6";
  appsecret = "fde0fdee0a17736629b6fbdc843a8dbd";
}

module.exports = {
  // 微信网页用户授权回调地址
  redirectUriWx,
  appID,
  appsecret,

  // 实名认证接口
  // 产品地址：https://market.cloud.tencent.com/products/5290
  // 实名认证分配的密钥Id
  // idCardSecretID: "AKID66uadhzxta351szkosnyzLv434vmsvk0hxwy",
  // 实名认证分配的密钥Key
  // idCardSecretKey: "ES2RZx2sl1kR66bU1Bw073i32JeA71BikU514qvk",

  // 云函数接口api地址
  nftShopApi,
  // 微信支付SDK类型
  payTypeEunm: {
    JSAPI: 0,
    APP: 1,
    H5: 2,
    NATIVE: 3,
    MINIPROG: 4,
  },
  // 加盐随机字符串
  SALTSTR: "Z8EU2LdAqsXOhuj5",
  // 活动站url
  eventApiHost: "https://events.geekpark.net",
  // if2023 activity_id
  // activityId: "78f21f72-d1d0-458a-a83b-f9a7e33cf6d6",
  activityId: "32fedb1d-3c4a-4423-b2fb-86962b523ed6",
};
