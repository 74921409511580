import request from "../utils/request";
import { nftShopApi } from "../../common.config";
import wx from "weixin-js-sdk";
import "../styles/common.less";
import JsEncrypt from "jsencrypt";
import MD5 from "crypto-js/md5";

// 获取地址栏的query值
export function getQueryString(name) {
  const reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
  const result = window.location.search.substring(1).match(reg);
  if (result != null) {
    return result[2];
  } else {
    return null;
  }
}

// 是否微信客户端
export function isWechat() {
  var ua = window.navigator.userAgent.toLowerCase();
  if (ua.match(/micromessenger/i) == "micromessenger") {
    return true;
  } else {
    return false;
  }
}

// 请求微信jssdk的 config 接口注入权限验证配置
export function initWxJssdk(callback) {
  if (!isWechat()) return;
  const curUrl = window.location.href.split("#")[0];
  let configParams = {
    // url: encodeURI(curUrl),
    url: curUrl,
  };
  request
    .post(`${nftShopApi}/wechat/jsapi_ticket`, configParams)
    .then((res) => {
      const data = res.data;
      const pramas = { appId: data.appid, timestamp: data.timestamp, nonceStr: data.noncestr, signature: data.signature };
      wx.config({
        ...pramas,
        jsApiList: ["checkJsApi", "chooseWXPay", "updateAppMessageShareData", "updateTimelineShareData"],
        debug: false,
      });
      wx.ready(() => {
        callback && callback(pramas);
      });
    })
    .catch((err) => {
      Toast.fail("wx-api授权失败！");
      console.error("获取jssdk config：", err);
    });
}

/*
生成带水印的分享图片
imgUrl:首页分享图
eleList:要添加的藏品图片文字信息{title:藏品名称。。。}
eleList = [{ type: "img", src: "", top: 1556, left: 790, width: 210, height: 210 }];
*/
export function genShareImg(imgUrl, infoH = 300, eleList, callback) {
  const cw = 1080; // 画布的宽度
  const pd = 40; // 藏品大图相对画布的内边距
  // infoH = 300; // 藏品大图下方藏品信息所占高度

  const imgObjs = [];
  if (eleList && eleList.length > 0) {
    eleList.forEach((item) => {
      const img = new Image();
      img.src = item.src;
      img.crossOrigin = "anonymous";
      imgObjs.push(img);
    });
  }
  const mainImg = new Image();
  mainImg.src = imgUrl;
  mainImg.crossOrigin = "anonymous"; // 解决canvas图片跨域
  mainImg.onload = function () {
    const imgOriginW = mainImg.width;
    const imgOriginH = mainImg.height;
    const imgWHRate = imgOriginH / imgOriginW;

    const canvas = document.createElement("canvas");
    canvas.width = cw;
    canvas.height = cw * imgWHRate + infoH;

    mainImg.width = cw - pd * 2;
    mainImg.height = mainImg.width * imgWHRate;

    const ctx = canvas.getContext("2d");

    // 绘制画布的纯白背景
    ctx.fillStyle = "#ffffff";
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    // 绘制藏品图
    ctx.drawImage(mainImg, pd, pd, mainImg.width, mainImg.height);

    if (eleList && eleList.length > 0) {
      eleList.forEach((item, index) => {
        if (item.type === "img") {
          ctx.drawImage(imgObjs[index], item.left, item.top, item.width, item.height);
        } else {
          ctx.textAlign = item.align;
          ctx.font = item.font;
          ctx.fillStyle = item.fillStyle;
          ctx.fillText(item.title, item.left, item.top);
        }
      });
    }

    // 绘制一行文本
    // ctx.textAlign = "left";
    // ctx.font = "40px Avenir, Helvetica, Arial, sans-serif";
    // ctx.fillStyle = "rgb(0,0,0)";
    // ctx.fillText(info.title, pd, img.height + pd + infoPdTop + 60);

    let base64Url = "";
    base64Url = canvas.toDataURL("image/png");
    callback && callback(base64Url);
  };
}

/**
 * 数字藏品分享图片
 * imgUrl:背景大图
 * eleList:图片&文字信息数据
 */
export function shareCollectionImg(imgUrl, infoH = 300, eleList, callback) {
  const cw = 1080; // 画布的宽度
  const pd = 0; // 藏品大图相对画布的内边距
  // infoH = 300; // 藏品大图下方藏品信息所占高度

  /**
   * 文字换行
   * @param {*} ctx canvas绘图上下文
   * @param {*} str 需要绘制的文本内容
   * @param {*} draw_width 绘制后的文字显示宽度
   * @param {*} lineNum 最大行数，多出部分用'...'表示， 如果传-1可以达到自动换行效果
   * @param {*} left 绘制文字的起点 X 轴坐标
   * @param {*} top 绘制文字的起点 Y 轴坐标
   * @param {*} steps 文字行间距
   * @param {*} font 文字样式
   * @param {*} fillStyle 文字填充色
   */
  function toFormateStr(ctx, str, draw_width, lineNum, left, top, steps, font, fillStyle) {
    let strWidth = ctx.measureText(str).width; // 测量文本源尺寸信息（宽度）
    let startpoint = top,
      keyStr = "",
      sreLN = strWidth / draw_width;
    let liner = Math.ceil(sreLN); // 计算文本源一共能生成多少行
    let strlen = parseInt(str.length / sreLN); // 等比缩放测量一行文本显示多少个字符

    // 若文本不足一行，则直接绘制，反之大于传入的最多行数（lineNum）以省略号（...）代替
    if (strWidth < draw_width) {
      ctx.font = font || "normal 32px/32px geekOppoB,SimHei,arial,sans-serif";
      ctx.fillStyle = fillStyle || "#000";
      ctx.fillText(str, left, startpoint);
    } else {
      for (let i = 1; i < liner + 1; i++) {
        let startPoint = strlen * (i - 1);
        if (i < lineNum || lineNum == -1) {
          keyStr = str.substr(startPoint, strlen);
          ctx.font = font || "normal 32px/32px geekOppoB,SimHei,arial,sans-serif";
          ctx.fillStyle = fillStyle || "#000";
          ctx.fillText(keyStr, left, startpoint);
        } else {
          keyStr = str.substr(startPoint, strlen - 2) + "...";
          ctx.font = font || "normal 32px/32px geekOppoB,SimHei,arial,sans-serif";
          ctx.fillStyle = fillStyle || "#000";
          ctx.fillText(keyStr, left, startpoint);
          break;
        }
        startpoint = startpoint + steps;
      }
    }
  }

  // 图片圆角
  function circleImg(ctx, img, x, y, r) {
    ctx.save();
    let d = 2 * r;
    let cx = x + r;
    let cy = y + r;
    ctx.arc(cx, cy, r, 0, 2 * Math.PI);
    ctx.clip();
    ctx.drawImage(img, x, y, d, d);
    ctx.restore();
  }

  const eleImgList = [];
  if (eleList && eleList.length > 0) {
    eleList.forEach((item, index) => {
      if (item.type === "img" || item.type === "avatarimg") {
        eleImgList.push(item);
      }
    });
  }

  if (eleList && eleList.length > 0) {
    let loadNum = 0;
    let imgTotal = eleImgList.length;
    eleList.forEach((item, index) => {
      if (imgTotal > 0) {
        if (item.type === "img" || item.type === "avatarimg") {
          const img = new Image();
          img.src = item.src;
          img.crossOrigin = "anonymous";
          img.onload = () => {
            item.img = img;
            loadNum++;
            if (loadNum == imgTotal) {
              const mainImg = new Image();
              mainImg.src = imgUrl;
              mainImg.crossOrigin = "anonymous"; // 解决canvas图片跨域

              mainImg.onload = function () {
                const imgOriginW = mainImg.width;
                const imgOriginH = mainImg.height;
                const imgWHRate = imgOriginH / imgOriginW;

                const canvas = document.createElement("canvas");
                canvas.width = cw;
                canvas.height = cw * imgWHRate + infoH;

                mainImg.width = cw - pd * 2;
                mainImg.height = mainImg.width * imgWHRate;

                const ctx = canvas.getContext("2d");

                // 绘制画布的纯白背景
                ctx.fillStyle = "#ffffff";
                ctx.fillRect(0, 0, canvas.width, canvas.height);

                // 绘制藏品图
                ctx.drawImage(mainImg, pd, pd, mainImg.width, mainImg.height);

                // 绘制其他其他图片
                eleList.forEach((item2, index) => {
                  if (item2.type === "img") {
                    ctx.drawImage(item2.img, item2.left, item2.top, item2.width, item2.height);
                  } else if (item2.type === "avatarimg") {
                    circleImg(ctx, item2.img, item2.left, item2.top, item2.width, item2.height);
                  } else if (item2.type === "sText") {
                    toFormateStr(ctx, item2.title, item2.draw_width, item2.lineNum, item2.left, item2.top, item2.steps, item2.font, item2.fillStyle);
                  } else {
                    ctx.font = item2.font || "normal 44px/44px geekOppoB,SimHei,arial,sans-serif";
                    ctx.fillStyle = item2.fillStyle || "#000";
                    ctx.fillText(item2.title, item2.left, item2.top);
                  }
                });

                let base64Url = "";
                base64Url = canvas.toDataURL("image/png");
                callback && callback(base64Url);
              };
            }
          };
        }
      } else {
        const mainImg = new Image();
        mainImg.src = imgUrl;
        mainImg.crossOrigin = "anonymous"; // 解决canvas图片跨域

        mainImg.onload = function () {
          const imgOriginW = mainImg.width;
          const imgOriginH = mainImg.height;
          const imgWHRate = imgOriginH / imgOriginW;

          const canvas = document.createElement("canvas");
          canvas.width = cw;
          canvas.height = cw * imgWHRate + infoH;

          mainImg.width = cw - pd * 2;
          mainImg.height = mainImg.width * imgWHRate;

          const ctx = canvas.getContext("2d");

          // 绘制画布的纯白背景
          ctx.fillStyle = "#ffffff";
          ctx.fillRect(0, 0, canvas.width, canvas.height);

          // 绘制藏品图
          ctx.drawImage(mainImg, pd, pd, mainImg.width, mainImg.height);

          // 绘制其他文字信息
          eleList.forEach((item2, index) => {
            ctx.textAlign = item2.align || "left";
            ctx.font = item2.font || "normal 44px/44px geekOppoB,SimHei,arial,sans-serif";
            ctx.fillStyle = item2.fillStyle;
            ctx.fillText(item2.title, item2.left, item2.top);
          });

          // TODO 隐藏普通图片和版本号码 怎样动态的拼接长度
          // ------绘制文本结束------

          let base64Url = "";
          base64Url = canvas.toDataURL("image/png");
          callback && callback(base64Url);
        };
      }
    });
  }
}

// 格式化AV返回的数据对象
const isPlainObject = (target) => target && target.toString() == "[object Object]" && Object.getPrototypeOf(target) == Object.prototype;
const _jsonify = (target) => {
  if (target && typeof target.toJSON === "function") return target.toJSON();
  if (Array.isArray(target)) return target.map(_jsonify);
  return target;
};
export const jsonify = (target) =>
  isPlainObject(target)
    ? Object.keys(target).reduce(
        (result, key) => ({
          ...result,
          [key]: _jsonify(target[key]),
        }),
        {}
      )
    : _jsonify(target);

// 获取服务器时间
export async function getServerTime() {
  try {
    const res = await request.get(`${nftShopApi}/common/current_time`);
    return res;
  } catch (e) {
    return new Date().toISOString();
  }
}

// 判断客户端类型
export const browserType = {
  versions: (function () {
    let u = navigator.userAgent;
    return {
      // 移动终端浏览器版本信息
      trident: u.indexOf("Trident") > -1, //IE内核
      presto: u.indexOf("Presto") > -1, //opera内核
      webKit: u.indexOf("AppleWebKit") > -1, //苹果、谷歌内核
      gecko: u.indexOf("Gecko") > -1 && u.indexOf("Khtml") == -1, //火狐内核
      mobile: !!u.match(/AppleWebKit.*Mobile.*/), //是否为移动终端
      ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios终端
      android: u.indexOf("Android") > -1 || u.indexOf("Linux") > -1, //android终端或者uc浏览器,
      iPhone: u.indexOf("iPhone") > -1, //是否为iPhone或者QQHD浏览器
      iPad: u.indexOf("iPad") > -1, //是否iPad
      webApp: u.indexOf("Safari") == -1, //是否web应用程序，没有头部与底部
      wx: u.indexOf("MicroMessenger") > 0, //是否是微信
    };
  })(),
  language: (navigator.browserLanguage || navigator.language).toLowerCase(),
};

// 处理RSA加密
export function encodeRSA(str1) {
  // MD5加密盐
  const encodeStr = encodeURIComponent(str1);
  const md5Str = MD5(encodeStr).toString();
  // RSA加密内容
  const jsEncrypt = new JsEncrypt();
  const publicString = "-----BEGIN PUBLIC KEY-----\n" + "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDXg/5YZDXWdpNNm0TFBTVl+7Co\n" + "JPN6fnnbqLXvOIzNy4qMkyJQSHDX/mbzvv+zBVKHhaHSSpOfz2ws8j8q2MSHjX22\n" + "BQxOVbiieQLHJnKCC+muQtU8vijQ69Egzgp5FTG23nYwffKu7zzv1Kl7+Nk4cMl3\n" + "i/qml3SWl5QgwyOUSwIDAQAB\n" + "-----END PUBLIC KEY-----";
  jsEncrypt.setPublicKey(publicString);
  const encryptedStr = jsEncrypt.encrypt(md5Str);
  return encryptedStr;
}

// 非标准iso转为标准iso
// "2022-10-26 6:46:53.911000+08:00" 或者
// "2022-10-26 6:46:53.911000" 或者
// "2022-10-26 16:46:53.911000"
// =========输出=================
// "2022-10-26T06:46:53.911000+08:00"
export function date2ISO(dateStr) {
  let tempStr = "";
  if (dateStr) {
    const dateStrs = dateStr.split(/\s+/);
    if (dateStrs.length === 1) {
      tempStr = dateStrs[0];
    } else {
      let h = dateStrs[1].split(":");
      if (h[0].length === 1) {
        h[0] = "T0" + h[0];
      } else {
        h[0] = "T" + h[0];
      }
      const hFormat = h.reduce((a, b) => {
        return a + ":" + b;
      });
      tempStr = dateStrs[0] + hFormat;
    }
  } else {
    tempStr = "";
  }
  let tempStrArr = tempStr.split("+");
  if (tempStrArr.length === 1) {
    tempStr = tempStr + "+08:00";
  }
  return tempStr;
}