import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import Layout from "../layout/Layout.vue";

const routes = [
  {
    path: "/",
    name: "Index",
    component: Layout,
    redirect: "/home",
    children: [
      {
        path: "/home",
        name: "Home",
        component: Home,
      },
      {
        path: "/schedule",
        name: "Schedule",
        component: () => import(/* webpackChunkName: "schedule" */ "../views/Schedule.vue"),
      },
      {
        path: "/buy",
        name: "Buy",
        component: () => import(/* webpackChunkName: "buyPage" */ "../views/BuyPage.vue"),
      },
      {
        path: "/mine",
        name: "Mine",
        component: () => import(/* webpackChunkName: "mine" */ "../views/Mine.vue"),
      },
    ],
  },
  {
    path: "/login",
    name: "Login",
    component: () => import(/* webpackChunkName: "login" */ "../views/Login.vue"),
  },
  {
    path: "/edit-userinfo",
    name: "EditUserinfo",
    component: () => import(/* webpackChunkName: "editUserinfo" */ "../views/EditUserinfo.vue"),
  },
  {
    path: "/my-tickets",
    name: "MyTickets",
    component: () => import(/* webpackChunkName: "myTickets" */ "../views/MyTickets.vue"),
  },
  {
    path: "/ticket-detail",
    name: "TicketDetail",
    component: () => import(/* webpackChunkName: "ticketDetail" */ "../views/TicketDetail.vue"),
  },
  {
    path: "/submit-order",
    name: "SubmitOrder",
    component: () => import(/* webpackChunkName: "submitOrder" */ "../views/SubmitOrder.vue"),
  },
  {
    path: "/buy-student",
    name: "BuyPageStudent",
    component: () => import(/* webpackChunkName: "buyPageStudent" */ "../views/BuyPageStudent.vue"),
  },
  {
    path: "/user-service-agreement",
    name: "UserServiceAgreement",
    component: () => import(/* webpackChunkName: "userServiceAgreement" */ "../views/UserServiceAgreement.vue"),
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: () => import(/* webpackChunkName: "privacyPolicy" */ "../views/PrivacyPolicy.vue"),
  },
  {
    path: "/check-pay-status",
    name: "CheckPayStatus",
    component: () => import(/* webpackChunkName: "checkPayStatus" */ "../views/CheckPayStatus.vue"),
  },
  {
    path: "/add-grounp-buy",
    name: "AddGrounpBuy",
    component: () => import(/* webpackChunkName: "addGrounpBuy" */ "../views/AddGrounpBuy.vue"),
  },
  {
    path: "/add-grounp",
    name: "AddGrounp",
    component: () => import(/* webpackChunkName: "addGrounp" */ "../views/AddGrounp.vue"),
  },
  {
    path: "/user-orders",
    name: "UserOrders",
    component: () => import(/* webpackChunkName: "userOrders" */ "../views/UserOrders.vue"),
  },
  {
    path: "/contact-us",
    name: "ContactUs",
    component: () => import(/* webpackChunkName: "contactUs" */ "../views/ContactUs.vue"),
  },
  {
    path: "/about-if",
    name: "AboutIF",
    component: () => import(/* webpackChunkName: "aboutIF" */ "../views/AboutIF.vue"),
  },
  {
    path: "/see-if-badge",
    name: "SeeIfBadge",
    component: () => import(/* webpackChunkName: "seeIfBadge" */ "../views/SeeIfBadge.vue"),
  },
  {
    path: "/common-question",
    name: "CommonQuestion",
    component: () => import(/* webpackChunkName: "commonQuestion" */ "../views/CommonQuestion.vue"),
  },
  {
    path: "/distribution",
    name: "Distribution",
    component: () => import(/* webpackChunkName: "distribution" */ "../views/Distribution.vue"),
  },
  {
    path: "/redeem-coupon",
    name: "RedeemCoupon",
    component: () => import(/* webpackChunkName: "redeemCoupon" */ "../views/RedeemCoupon.vue"),
  },
  {
    path: "/user-order-detail",
    name: "UserOrderDetail",
    component: () => import(/* webpackChunkName: "userOrderDetail" */ "../views/UserOrderDetail.vue"),
  },
  // 未匹配的路径全部重定向到home页
  {
    path: "/:pathMatch(.*)*",
    name: "*",
    redirect: (to) => {
      return { path: "/home" };
    },
  },
];

const router = createRouter({
  // history: createWebHashHistory(),
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // 始终滚动到顶部
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

export default router;
