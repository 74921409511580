import axios from "axios";
import AV from "../utils/av";

const instance = axios.create({
  withCredentials: true,
  timeout: 60000,
});

// 添加请求拦截器
instance.interceptors.request.use(
  function (config) {
    const currentUser = AV.User.current();
    let customHeaders = {};
    if (currentUser) {
      customHeaders["X-LC-Session"] = currentUser.getSessionToken();
    }
    config.headers = { ...config.headers, ...customHeaders };
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// 添加响应拦截器
instance.interceptors.response.use(
  function (response) {
    return response.data;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default instance;
