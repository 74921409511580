import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import { Button, Icon, Loading, Form, Field, CellGroup, ConfigProvider, Cell, Tab, Tabs, Popup, Uploader, Checkbox, CheckboxGroup, Lazyload, List, Tabbar, TabbarItem, Empty, Toast } from "vant";
import "vant/es/toast/style";
import "vant/es/dialog/style";
import "vant/es/notify/style";
import AV from "./assets/utils/av";
import { browserType, getQueryString, jsonify } from "./assets/utils/common.utils";
import request from "./assets/utils/request";
import { nftShopApi, appID, appsecret } from "./common.config";
import dayjs from "dayjs";

// if (browserType.versions.wx && process.env.VUE_APP_PRE_PRODUCTION === "T") {
//   let vConsole = new window.VConsole({});
// }

const app = createApp(App);

app.use(Button);
app.use(Icon);
app.use(Loading);
app.use(Form);
app.use(Field);
app.use(CellGroup);
app.use(ConfigProvider);
app.use(Cell);
app.use(Tab);
app.use(Tabs);
app.use(Popup);
app.use(Uploader);
app.use(Checkbox);
app.use(CheckboxGroup);
app.use(Lazyload);
app.use(List);
app.use(Tabbar);
app.use(TabbarItem);
app.use(Empty);

router.beforeEach((to, from, next) => {
  const user = AV.User.current();
  const currentUser = user && user.toJSON();
  const notRequireLoginPages = ["Index", "Home", "Schedule", "Buy", "Mine", "UserServiceAgreement", "PrivacyPolicy", "AddGrounp", "CommonQuestion", "AboutIF", "ContactUs", "TicketDetail"];
  // 获取openId绑定微信
  bindWX();
  // 用户绑定分销员
  bindDistributionRelated();
  // 登录权限判断
  if (!currentUser) {
    if (notRequireLoginPages.indexOf(to.name) > -1 || to.name === "Login") {
      next();
    } else {
      let fullPath = from.fullPath;
      if (from.name === "TicketDetail") {
        fullPath = "/buy";
      }
      const origin = window.location.origin;
      let redirectUriByEncode = "";
      if (fullPath) {
        redirectUriByEncode = encodeURIComponent(origin + fullPath);
        next({ name: "Login", query: { redirectUri: redirectUriByEncode, redirectFullPath: fullPath } });
      } else {
        next({ name: "Login" });
      }
    }
  } else {
    if (notRequireLoginPages.indexOf(to.name) > -1) {
      next();
    } else if (to.name === "Login") {
      next({ name: "Home" });
    } else if (to.name === "Distribution") {
      if (currentUser.roles.includes("distribution")) {
        next();
      } else {
        next("Home");
      }
    } else {
      next();
    }
  }
});

// 获取openId绑定微信
const bindWX = () => {
  const user = AV.User.current();
  const currentUser = user && user.toJSON();
  const code = getQueryString("code");
  if (user && !currentUser?.authData?.weixin && browserType.versions.wx && code) {
    // 微信授权回调
    request
      .post(`${nftShopApi}/wechat/authorization`, { wx_appid: appID, wx_secret: appsecret, code, auth_type: "base" })
      .then((userInfoRes) => {
        const { openid, access_token, expires_in, refresh_token, scope } = userInfoRes.data;
        const weixinData = {
          // 必须
          openid: openid,
          access_token: access_token,
          expires_in: expires_in,
          // 可选
          refresh_token: refresh_token,
          scope: scope,
        };
        user
          .associateWithAuthData(weixinData, "weixin")
          .then(function (user) {
            console.log("微信号绑定成功");
          })
          .catch(function (error) {
            if (error.code == 137) {
              Toast.fail("此微信号\n已被绑定");
            } else {
              Toast.fail("微信授权失败\n请重试");
            }
            AV.User.logOut();
            console.error("添加微信三方信息: ", error);
          });
      })
      .catch((err) => {
        Toast.fail("微信授权失败\n请重试");
        AV.User.logOut();
        console.error("获取微信用户信息: ", err);
      });
  }
};

// 用户绑定分销关系
const bindDistributionRelated = () => {
  let distributionId = localStorage.getItem("if2023/distributionId");
  if (!distributionId) {
    const distributionIdQuery = getQueryString("distributionId");
    if (distributionIdQuery) {
      localStorage.setItem("if2023/distributionId", distributionIdQuery);
      distributionId = distributionIdQuery;
    }
  }

  const user = AV.User.current();
  const userInfo = user && user.toJSON();

  if (distributionId && userInfo && userInfo.roles && !userInfo.roles.includes("distribution")) {
    const distributionRelatedQuery = new AV.Query("DistributionRelated");
    const userPointer = AV.Object.createWithoutData("_User", userInfo.objectId);
    distributionRelatedQuery.equalTo("user", userPointer);
    distributionRelatedQuery
      .first()
      .then((res) => {
        // 未被分销员绑定
        if (!res) {
          // 查询绑定时间是否过期
          const DistributionUserQuery = new AV.Query("DistributionUser");
          DistributionUserQuery.equalTo("objectId", distributionId);
          DistributionUserQuery.first()
            .then((res) => {
              const distributioInfo = jsonify(res);
              console.log("distributioInfo: ", distributioInfo);
              if (dayjs(distributioInfo?.endTime) > dayjs()) {
                // 增加一条绑定记录
                const distributionRelatedObject = AV.Object.extend("DistributionRelated");
                const distributionRelated = new distributionRelatedObject();
                const beRelatedUserPointer = AV.Object.createWithoutData("DistributionUser", distributionId);
                distributionRelated.set("beRelatedUser", beRelatedUserPointer);
                distributionRelated.set("user", userPointer);
                distributionRelated
                  .save()
                  .then((res) => {
                    console.log("分销员绑定用户成功1");
                  })
                  .catch((err) => {
                    console.error("设置分销员和用户绑定关系：", err);
                  });
                // 对应分销员绑定数加一
                const distributionUser = AV.Object.createWithoutData("DistributionUser", distributionId);
                distributionUser.increment("relatedUserCount", 1);
                distributionUser
                  .save()
                  .then((res) => {
                    console.log("分销员绑定用户成功2");
                  })
                  .catch((err) => {
                    console.error("分销员信息表绑定用户加一：", err);
                  });
              }
            })
            .catch((err) => {
              console.error("查询分销员信息：", err);
            });
        }
      })
      .catch((err) => {
        console.error("查询用户是否已绑定分销员：", err);
      });
  }
};

app.use(router);
app.use(store);
app.mount("#app");
