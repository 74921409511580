import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-2df2e6cc"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "layout-root"
};
import BottomNavigatorBar from "../components/BottomNavigatorBar.vue";
export default {
  __name: 'Layout',

  setup(__props) {
    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");

      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_router_view), _createVNode(BottomNavigatorBar)]);
    };
  }

};